import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import groupPhoto from '../photos/homepage_header.png'

const Header = ({}) => (
  <div className="bg-pampas lg:bg-transparent relative">
    <div className="rotated-bg absolute bg-pampas lg:bg-transparent"></div>
    <div className="fc-header-wrapper">
      <header style={{ backgroundImage: `url(${groupPhoto})` }} className="fc-header">
      </header>
    </div>
    <div className="container lg:text-white fc-header-content lg:flex lg:items-end lg:pb-32 xl:pb-48 z-10">
      <div className="lg:w-8/12 z-10 py-6 lg:py-0" data-sal="slide-right" data-sal-once data-sal-delay="500" data-sal-duration="800">
        <h1 className="text-3xl lg:text-6xl leading-none font-display font-bold text-gradient lg:text-gradient-none">For more diversity in founding teams.</h1>
        <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 lg:bg-white lg:bg-gradient-l-white h-1 border-0 w-1/5 my-6" />
        <p className="font-light lg:text-xl leading-relaxed">
          Female Co-Founders setzt sich für mehr Frauen im Deutschen Startup-Ökosystem
          ein. Fokus: Mehr weibliche Co-Founder in Gründungsteams.
        </p>
      </div>
    </div>
  </div>
)

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
