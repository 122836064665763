import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import quoteIcon from '../icons/quote.svg';
import testimonialImg from '../photos/testimonial_unrotated.png';

const Section2 = ({}) => (
  <>
    <div className="rotated-bg md:hidden overflow-hidden">
      <img src={testimonialImg} className="max-w-none" style={{ width: '120vw', marginLeft: '-20vw' }} />
    </div>
    <div className="relative mb-16">
      <div className="rotated-bg absolute bg-vanilla-ice">
        <div className="container hidden md:block">
          <div className="md:w-2/5 lg:w-1/3 md:absolute md:bottom-0"
            data-sal="slide-right" data-sal-delay="200" data-sal-duration="500"><img src={testimonialImg} /></div>
        </div>
      </div>
      <section className="container py-10 md:py-0 z-10 text-center"
        data-sal="slide-down" data-sal-delay="200" data-sal-duration="500">
        <div className="md:flex">
          <div className="md:w-2/5">
          </div>
          <div className="md:w-3/5 md:pl-24">
            <img src={quoteIcon} alt="Quote" className="w-16 xl:w-24 mx-auto mb-4 xl:mb-12 block" />
            <blockquote className="leading-relaxed text-lg xl:text-xl">
              Das war ein Abend voller Inspiration & Austausch mit Frauen,
              die gründen wollen. Ich nehme tolle Kontakte mit und freue
              mich auf die weiteren Gespräche.

              <footer className="text-sm font-bold mt-4 md:pb-8">
                Frederike Möller, Teilnehmerin München April 2019
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </div>
  </>
)

Section2.propTypes = {
}

Section2.defaultProps = {
}

export default Section2
