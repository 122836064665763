import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/homepage/header"
import Navigation from "../components/navigation"
import Section1 from "../components/homepage/section1"
import Section2 from "../components/homepage/section2"
import Section3 from "../components/homepage/section3"
import Section4 from "../components/homepage/section4"
import UpcomingEvents from "../components/events/section3"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
    <Header />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <div className="pt-10 md:pt-0">
      <UpcomingEvents />
    </div>
  </Layout>
)

export default IndexPage
