import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import groupIcon from '../icons/group_icon.svg'

const Section1 = ({}) => (
  <section className="container lg:flex py-10 md:py-20 lg:py-32 xl2:py-40">
    <div className="lg:w-8/12" data-sal="slide-right" data-sal-delay="100" data-sal-duration="500">
      <div className="text-sm text-gradient uppercase font-display font-bold">Über uns</div>
      <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Female Co-Founders Initiative</h2>
      <hr className="border-meteor-500 border-2 w-1/5 my-6" />
      <p className="font-light leading-relaxed">
        Unsere Initiative hat sich der Vernetzung potentieller Co-Founder
        von Startups und anderen Gründungsprojekten verschrieben. Dabei setzen
        wir auf Events wie unsere Female Co-Founders Pitch Night, die deutschlandweit mit
        regionalen Partnern stattfindet sowie auf unsere starke Online-Community,
        die auf verschiedenen Plattformen zu Hause ist.
      </p>
    </div>
    <div className="hidden lg:w-4/12 lg:ml-20 lg:block" data-sal="slide-right" data-sal-delay="100" data-sal-duration="500">
      <img src={groupIcon} className="w-full" alt="Group" />
    </div>
  </section>
)

Section1.propTypes = {
}

Section1.defaultProps = {
}

export default Section1
